import {
  sendCompleteConfirmation,
  uploadDocument,
} from "@/models/upload.model";
import { toast } from "@/shadcn/hooks/use-toast";
import { DocumentQueueTracker, StoredDocument } from "@/types";
import localforage from "localforage";
import Swal from "sweetalert2";

localforage.config({
  name: "DocumentApp",
  storeName: "document_store",
});

let isProcessing = false; // Flag to indicate if a document is being processed

// #region syncDocuments
// Synchronize documents in the queue, happens when app is online
export const syncDocuments = async () => {
  if (isProcessing) {
    console.log("A document is already being processed.");
    return false;
  }

  const queue = await getUnUploadedDocumentQueue();
  const queueLength = queue.length;

  if (queueLength === 0) {
    console.log("No documents to synchronize.");
    return false;
  }

  console.log(`Found ${queueLength} documents. Starting upload...`);

  const staleDocuments: StoredDocument[] = [];
  const invalidDocuments: StoredDocument[] = [];

  toast({
    title: "Batch Processing",
    description: "Synchronizing documents...",
    duration: 3000,
  });

  let numProcessedDocs = 0;
  for (const doc of queue) {
    try {
      isProcessing = true; // Set the flag to indicate processing has started
      const res = await processDocumentForUpload(doc); // Upload each document

      if (res.rStatus === "success") {
        console.log(`Document ${doc.documentName} processed successfully.`);
        // Update the tracker
        await updateTrackerUploadNumber(doc.uuidGroup);
        await markDocumentAsComplete(doc.id);
        // await removeDocumentFromQueue(doc.id);

        // // Check if upload >= total
        // const isGroupComplete = await isGroupUploadGreaterEqual(doc.uuidGroup);

        // // Check if any in stale with groupID
        // if (isGroupComplete) {
        //   const isGroupIDStale = await isGroupIDInStaleQueue(doc.uuidGroup);

        //   // If not in stale queue, can submit complete
        //   if (!isGroupIDStale) {
        //     await markGroupIDComplete(doc.uuidGroup);
        //   }
        // }

        toast({
          title: "Success",
          description: `Document ${doc.documentName} processed successfully.`,
          duration: 3000,
        });
      } else {
        console.error(`Failed to upload document ${doc.id}:`, res.error);
        if (res.error === "network" || res.error === "serviceUnavailable") {
          if (res.error === "serviceUnavailable") {
            toast({
              title: "AI Service Unavailable",
              description: "Please contact support",
              duration: 3000,
            });
          }

          if (doc.status === "failed") {
            doc.status = "stale";
            await updateTrackerStaleNumber(doc.uuidGroup, "increment");
            staleDocuments.push(doc); // Keep track of stale documents
          } else {
            markDocumentAsFailed(doc.id);
          }
        } else {
          if (res.error === "badRequest") {
            Swal.fire({
              title: "Error",
              text: "Bad Request, Please contact support",
              icon: "error",
              confirmButtonText: "Ok",
            });
          }

          if (res.error === "imageProcessing") {
            toast({
              title: "Image Processing Error",
              description:
                "Data could not be extracted from the image. Please try with a different image.",
              duration: 3000,
            });
          }

          doc.status = "failed";
          await updateTrackerInvalidNumber(doc.uuidGroup, "increment");
          invalidDocuments.push(doc); // Keep track of invalid documents
          // remove from main queue
          await removeDocumentFromQueue(doc.id);
        }
      }
    } catch (error) {
      console.error(`Failed to upload document ${doc.id}:`, error);

      if (doc.status === "failed") {
        doc.status = "stale";
        staleDocuments.push(doc); // Keep track of stale documents
      } else {
        markDocumentAsFailed(doc.id);
      }
    } finally {
      isProcessing = false; // Clear the flag when processing is complete
    }
    numProcessedDocs++;
  }

  // Update the queue to include only stale documents
  await addToStaleQueue(staleDocuments);

  // Update the queue to include only invalid documents
  await addToInvalidQueue(invalidDocuments);

  console.log("Synchronization complete.");
  console.log("queueLength:", queueLength);
  console.log("numProcessedDocs:", numProcessedDocs);
  if (queueLength !== numProcessedDocs) {
    console.error("Mismatch in queue length and processed documents.");
  }
};
// #endregion syncDocuments

// #region addDocumentToQueue
// Add document to queue
// Input: doc (StoredDocument) - The document to be added to the queue
export const addDocumentToQueue = async (
  doc: StoredDocument
): Promise<boolean> => {
  try {
    const queue =
      (await localforage.getItem<StoredDocument[]>("documentQueue")) || [];
    queue.push(doc);
    await localforage.setItem("documentQueue", queue);
    const updatedQueue = await localforage.getItem<StoredDocument[]>(
      "documentQueue"
    );
    console.log("Current queue after push:", updatedQueue);
    const isDocInQueue = updatedQueue
      ? updatedQueue.some((d) => d.id === doc.id)
      : false;
    console.log(`Document ${doc.id} is in queue:`, isDocInQueue);
    return isDocInQueue;
  } catch (error) {
    console.error("Failed to add document to queue:", error);
    return false;
  }
};
// #endregion addDocumentToQueue

// #region isDocumentInQueue
// Check if document is in the queue
// Input: doc (StoredDocument.id) - The document to check
// Output: Promise<boolean> - True if document is in the queue
export const isDocumentInQueue = async (docID: string): Promise<boolean> => {
  const queue = await getDocumentQueue();
  return queue.some((doc) => doc.id === docID);
};
// #endregion isDocumentInQueue

// #region isGroupIDInQueue
// Check if groupID is in the queue
// Input: groupID (string) - The group ID to check
// Output: Promise<boolean> - True if groupID is in the queue
export const isGroupIDInQueue = async (groupID: string): Promise<boolean> => {
  const queue = await getDocumentQueue();
  return queue.some((doc) => doc.uuidGroup === groupID);
};
// #endregion isGroupIDInQueue

// #region isGroupIDInStaleQueue
// Check if groupID is in the stale queue
// Input: groupID (string) - The group ID to check
// Output: Promise<boolean> - True if groupID is in the stale queue
export const isGroupIDInStaleQueue = async (
  groupID: string
): Promise<boolean> => {
  const staleQueue = await getStaleQueue();
  return staleQueue.some((doc) => doc.uuidGroup === groupID);
};
// #endregion isGroupIDInStaleQueue

// #region removeDocumentFromQueue
// Remove document from queue
// Input: docID (string) - The document to be removed from the queue
// Output: Promise<boolean> - True if document is removed from the queue
export const removeDocumentFromQueue = async (
  docID: string
): Promise<boolean> => {
  try {
    const queue =
      (await localforage.getItem<StoredDocument[]>("documentQueue")) || [];
    const updatedQueue = queue.filter((doc) => doc.id !== docID);
    await localforage.setItem("documentQueue", updatedQueue);
    const isDocInQueue = updatedQueue.some((d) => d.id === docID);
    console.log(`Document ${docID} is in queue:`, isDocInQueue);
    return !isDocInQueue;
  } catch (error) {
    console.error("Failed to remove document from queue:", error);
    return false;
  }
};
// #endregion removeDocumentFromQueue

// #region markDocumentAsComplete
// Mark document as complete
// Input: docID (string) - The document to be marked as complete
// Output: Promise<boolean> - True if document is marked as complete
export const markDocumentAsComplete = async (
  docID: string
): Promise<boolean> => {
  try {
    const queue =
      (await localforage.getItem<StoredDocument[]>("documentQueue")) || [];
    const updatedQueue = queue.map((doc) => {
      if (doc.id === docID) {
        doc.status = "uploaded";
      }
      return doc;
    });
    await localforage.setItem("documentQueue", updatedQueue);
    const isDocComplete = updatedQueue.some(
      (doc) => doc.id === docID && doc.status === "uploaded"
    );
    console.log(`Document ${docID} is complete:`, isDocComplete);
    return isDocComplete;
  } catch (error) {
    console.error("Failed to mark document as complete:", error);
    return false;
  }
};
// #endregion markDocumentAsComplete

export const markDocumentAsFailed = async (docID: string): Promise<boolean> => {
  console.log(`Marking document ${docID} as failed...`);
  try {
    const queue =
      (await localforage.getItem<StoredDocument[]>("documentQueue")) || [];
    const updatedQueue = queue.map((doc) => {
      if (doc.id === docID) {
        doc.status = "failed";
      }
      return doc;
    });
    await localforage.setItem("documentQueue", updatedQueue);
    const isDocFailed = updatedQueue.some(
      (doc) => doc.id === docID && doc.status === "failed"
    );
    console.log(`Document ${docID} is failed:`, isDocFailed);
    return isDocFailed;
  } catch (error) {
    console.error("Failed to mark document as failed:", error);
    return false;
  }
};

// #region getDocumentQueue
// Get queued documents, happens when app is online
// Output: Promise<StoredDocument[]> - The list of documents in the queue
export const getDocumentQueue = async (): Promise<StoredDocument[]> => {
  return (await localforage.getItem<StoredDocument[]>("documentQueue")) || [];
};
// #endregion getDocumentQueue

// #region updateDocumentQueue
// Update the queue with only failed documents
// Input: failedDocs (StoredDocument[]) - The documents that failed to upload
// Output: Promise<void>
export const updateDocumentQueue = async (
  failedDocs: StoredDocument[]
): Promise<void> => {
  // get existing docs
  const documentQueue =
    (await localforage.getItem<StoredDocument[]>("documentQueue")) || [];

  // remove matching failed docs from old queue
  const updatedQueue = documentQueue.filter(
    (doc) => !failedDocs.some((fDoc) => fDoc.id === doc.id)
  );

  // add incoming failed docs to the queue
  updatedQueue.push(...failedDocs);

  // set the updated queue
  await localforage.setItem("documentQueue", updatedQueue);
};
// #endregion updateDocumentQueue

// #region addToStaleQueue
// Add to stale queue
// Input: doc (StoredDocument[]) - The document to be added to the stale queue
// Output: Promise<boolean> - True if document is in the stale queue
export const addToStaleQueue = async (
  doc: StoredDocument[]
): Promise<boolean> => {
  try {
    const staleQueue =
      (await localforage.getItem<StoredDocument[]>("staleQueue")) || [];
    staleQueue.push(...doc);
    await localforage.setItem("staleQueue", staleQueue);
    return staleQueue.includes(doc[0]);
  } catch (error) {
    console.error("Failed to add document to stale queue:", error);
    return false;
  }
};
// #endregion addToStaleQueue

// #region removeDocumentFromStaleQueue
// Remove document from stale queue
// Input: docID (string) - The document to be removed from the stale queue
// Output: Promise<boolean> - True if document is removed from the stale queue
export const removeDocumentFromStaleQueue = async (
  docID: string
): Promise<boolean> => {
  try {
    // Step 1: Retrieve the current stale queue from local storage
    const staleQueue =
      (await localforage.getItem<StoredDocument[]>("staleQueue")) || [];

    // Step 2: Filter out the document with the specified docID from the stale queue
    const updatedQueue = staleQueue.filter((doc) => doc.id !== docID);

    // Step 3: Save the updated queue back to local storage
    await localforage.setItem("staleQueue", updatedQueue);

    // Step 4: Check if the document with the specified docID is still in the updated queue
    // Return true if the document was successfully removed, otherwise false
    return !updatedQueue.some((doc) => doc.id === docID);
  } catch (error) {
    console.error("Failed to remove document from stale queue:", error);
    return false;
  }
};
// #endregion removeDocumentFromStaleQueue

// #region removeDocumentsByGroupIDInStaleQueue
// Remove all documents by groupID in stale queue
// Input: groupID (string) - The group ID to remove all documents
// Output: Promise<boolean> - True if all documents are removed
export const removeDocumentsByGroupIDInStaleQueue = async (
  groupID: string
): Promise<boolean> => {
  try {
    // Step 1: Retrieve the current stale queue from local storage
    const staleQueue =
      (await localforage.getItem<StoredDocument[]>("staleQueue")) || [];

    // Step 2: Filter out the documents with the specified groupID from the stale queue
    const updatedQueue = staleQueue.filter((doc) => doc.uuidGroup !== groupID);

    // Step 3: Save the updated queue back to local storage
    await localforage.setItem("staleQueue", updatedQueue);

    // Step 4: Check if the documents with the specified groupID are still in the updated queue
    // Return true if the documents were successfully removed, otherwise false
    return !updatedQueue.some((doc) => doc.uuidGroup === groupID);
  } catch (error) {
    console.error(
      `Failed to remove documents by groupID ${groupID} in stale queue:`,
      error
    );
    return false;
  }
};
// #endregion removeDocumentsByGroupIDInStaleQueue

// #region getStaleQueueByGroupID
// Get stale queue by groupID
// Input: groupID (string) - The group ID to get the stale queue
// Output: Promise<StoredDocument[]> - The list of stale documents
export const getStaleQueueByGroupID = async (
  groupID: string
): Promise<StoredDocument[]> => {
  const staleQueue = await getStaleQueue();
  return staleQueue.filter((doc) => doc.uuidGroup === groupID);
};
// #endregion getStaleQueueByGroupID

// #region removeDocFromStaleQueue
// Remove doc from stale queue with docID
// Input: docID (string) - The document to be removed from the stale queue
// Output: Promise<boolean> - True if document is removed from the stale queue
export const removeDocFromStaleQueue = async (
  docID: string
): Promise<boolean> => {
  try {
    const staleQueue = await getStaleQueue();
    const updatedQueue = staleQueue.filter((doc) => doc.id !== docID);
    await localforage.setItem("staleQueue", updatedQueue);
    return !updatedQueue.some((doc) => doc.id === docID);
  } catch (error) {
    console.error("Failed to remove document from stale queue:", error);
    return false;
  }
};
// #endregion removeDocFromStaleQueue

// #region getStaleQueue
// Get stale queue
// Output: Promise<StoredDocument[]> - The list of stale documents
export const getStaleQueue = async (): Promise<StoredDocument[]> => {
  return (await localforage.getItem<StoredDocument[]>("staleQueue")) || [];
};
// #endregion getStaleQueue

// #region clearStaleQueue
// Clear stale queue
// Output: Promise<void>
export const clearStaleQueue = async (): Promise<void> => {
  await localforage.removeItem("staleQueue");
};
// #endregion clearStaleQueue

// #region addStaleToDocumentQueue
// Add document from stale queue back to document queue
// Input: groupID - Find all documents with this groupID in stale queue
// Output: Promise<boolean> - True if document is added back to the queue
export const addStaleToDocumentQueue = async (
  groupID: string
): Promise<boolean> => {
  try {
    const staleQueue = await getStaleQueue();
    // Filter by groupID
    const updatedQueue = staleQueue.filter((doc) => doc.uuidGroup === groupID);

    if (updatedQueue.length === 0) {
      console.log(
        `No documents found in the stale queue for groupID: ${groupID}`
      );
      return false;
    }

    // Change status from stale to pending
    updatedQueue.forEach((doc) => {
      doc.status = "pending";
    });

    const documentQueue = await getDocumentQueue();
    documentQueue.push(...updatedQueue);
    await localforage.setItem("documentQueue", documentQueue);

    // For each document in the stale queue, remove from stale queue
    await removeDocumentsByGroupIDInStaleQueue(groupID);

    // if removed, update the tracker
    await updateTrackerStaleNumber(groupID, "remove");

    console.log(
      `Moved ${updatedQueue.length} documents from stale queue to document queue for groupID: ${groupID}`
    );
    return true;
  } catch (error) {
    console.error(
      `Failed to add stale to document queue for groupID: ${groupID}`,
      error
    );
    return false;
  }
};
// #endregion addStaleToDocumentQueue

// #region processDocumentForUpload
// Process document for upload based on function name
// Input: doc (StoredDocument) - The document to be processed
// Output: Promise<void>
const processDocumentForUpload = async (
  doc: StoredDocument
): Promise<
  | {
      rStatus: "success";
    }
  | {
      rStatus: "error";
      error:
        | "network"
        | "badRequest"
        | "imageProcessing"
        | "serviceUnavailable"
        | (string & {});
    }
> => {
  switch (doc.functionName) {
    case "uploadDocument": {
      const res = await uploadDocument(doc);
      if (res.rStatus === "error") {
        console.error(`Failed to upload document ${doc.id}:`, res.error);
        return {
          rStatus: "error",
          error: res.error,
        };
      }
      return {
        rStatus: "success",
      };
    }
    case "loadDocument":
      console.log(`Document ${JSON.stringify(doc)} loaded successfully.`);
      toast({
        title: "Success",
        description: "Document Batch Uploaded",
        duration: 3000,
      });
      return {
        rStatus: "success",
      };
    default:
      throw new Error(`Unknown function: ${doc.functionName}`);
  }
};
// #endregion processDocumentForUpload

// #region addGroupIDToTracker
// Add group ID to tracker
// Input: groupID (string) - The group ID to be added to the tracker
//        queueTracker (DocumentQueueTracker) - The tracker to be updated
// Output: Promise<boolean> - True if group ID is added to the tracker
export const addGroupIDToTracker = async (
  groupID: string,
  queueTracker: DocumentQueueTracker
): Promise<boolean> => {
  try {
    await localforage.setItem<DocumentQueueTracker>(
      `queueTracker-${groupID}`,
      queueTracker
    );

    const updatedTracker = await localforage.getItem<DocumentQueueTracker>(
      `queueTracker-${groupID}`
    );

    if (updatedTracker) {
      console.log(`Group ID ${groupID} tracker updated:`, updatedTracker);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Failed to add group ID to tracker:", error);
    return false;
  }
};
// #endregion addGroupIDToTracker

// #region getTrackerByGroupID
// Get tracker by groupID
// Input: groupID (string) - The group ID to get the tracker
// Output: Promise<DocumentQueueTracker> - The tracker for the group ID
export const getTrackerByGroupID = async (
  groupID: string
): Promise<DocumentQueueTracker | null> => {
  const tracker = await localforage.getItem<DocumentQueueTracker>(
    `queueTracker-${groupID}`
  );
  if (tracker) {
    console.log(`Tracker for group ID ${groupID}:`, tracker);
    return tracker;
  }
  return null;
};
// #endregion getTrackerByGroupID

// #region updateTrackerUploadNumber
// Update tracker upload number by groupID
// Input: groupID (string) - The group ID to update the tracker
// Output: Promise<boolean> - True if tracker is updated
export const updateTrackerUploadNumber = async (
  groupID: string
): Promise<boolean> => {
  try {
    const tracker = await getTrackerByGroupID(groupID);
    if (tracker) {
      tracker.uploaded += 1;
      await addGroupIDToTracker(groupID, tracker);
      return true;
    }

    return false;
  } catch (error) {
    console.error("Failed to update tracker upload number:", error);
    return false;
  }
};
// #endregion updateTrackerUploadNumber

// #region updateTrackerStaleNumber
// Update tracker stale number by groupID
// Input: groupID (string) - The group ID to update the tracker
// Output: Promise<boolean> - True if tracker is updated
export const updateTrackerStaleNumber = async (
  groupID: string,
  action: "increment" | "decrement" | "remove"
): Promise<boolean> => {
  try {
    const tracker = await getTrackerByGroupID(groupID);
    if (tracker) {
      if (action === "increment") {
        tracker.stale += 1;
      } else if (action === "decrement") {
        tracker.stale -= 1;
      } else if (action === "remove") {
        tracker.stale = 0;
      }
      await addGroupIDToTracker(groupID, tracker);
      return true;
    }

    return false;
  } catch (error) {
    console.error("Failed to update tracker stale number:", error);
    return false;
  }
};
// #endregion updateTrackerStaleNumber

// #region updateTrackerInvalidNumber
export const updateTrackerInvalidNumber = async (
  groupID: string,
  action: "increment" | "decrement" | "remove"
): Promise<boolean> => {
  try {
    const tracker = await getTrackerByGroupID(groupID);
    if (tracker) {
      if (action === "increment") {
        tracker.invalid += 1;
      } else if (action === "decrement") {
        tracker.invalid -= 1;
        tracker.total -= 1;
      } else if (action === "remove") {
        tracker.invalid = 0;
      }
      await addGroupIDToTracker(groupID, tracker);
      return true;
    }

    return false;
  } catch (error) {
    console.error("Failed to update tracker invalid number:", error);
    return false;
  }
};
// #endregion updateTrackerInvalidNumber

// #region isGroupUploadGreaterEqual
// is uploaded >= total
// Input: groupID (string) - The group ID to check if complete
// Output: Promise<boolean> - True if uploaded >= total
export const isGroupUploadGreaterEqual = async (
  groupID: string
): Promise<boolean> => {
  const tracker = await getTrackerByGroupID(groupID);
  if (tracker) {
    return tracker.uploaded >= tracker.total;
  }
  return false;
};
// #endregion isGroupUploadGreaterEqual

// #region markGroupIDComplete
export const markGroupIDComplete = async (
  groupID: string
): Promise<boolean> => {
  try {
    const sendConfirmation = await sendCompleteConfirmation(groupID);
    if (sendConfirmation.rStatus === "success") {
      const tracker = await getTrackerByGroupID(groupID);
      if (tracker) {
        tracker.completed = true;
        await addGroupIDToTracker(groupID, tracker);
        await removeAllDocsByGroupID(groupID);
        return true;
      }
    } else {
      const tracker = await getTrackerByGroupID(groupID);
      if (tracker) {
        tracker.completed = false;
        await addGroupIDToTracker(groupID, tracker);
        return false;
      }
    }

    return false;
  } catch (error) {
    console.error("Failed to mark group ID complete:", error);
    return false;
  }
};
// #endregion markGroupIDComplete

// #region getAllTrackers
// Get all trackers
// Output: Promise<DocumentQueueTracker[]> - The list of all trackers
export const getAllTrackers = async (): Promise<DocumentQueueTracker[]> => {
  const keys = await localforage.keys();
  const trackerKeys = keys.filter((key) => key.includes("queueTracker"));
  const trackers = await Promise.all(
    trackerKeys.map((key) => localforage.getItem<DocumentQueueTracker>(key))
  );

  if (trackers) {
    return trackers.filter(
      (tracker): tracker is DocumentQueueTracker => tracker !== null
    );
  }

  return [];
};
// #endregion getAllTrackers

// #region removeTrackerByGroupID
// Remove tracker by groupID
// Input: groupID (string) - The group ID to remove the tracker
// Output: Promise<boolean> - True if tracker is removed
export const removeTrackerByGroupID = async (
  groupID: string
): Promise<boolean> => {
  try {
    await localforage.removeItem(`queueTracker-${groupID}`);
    const tracker = await localforage.getItem<DocumentQueueTracker>(
      `queueTracker-${groupID}`
    );
    console.log(`Tracker for group ID ${groupID} removed:`, tracker);
    return !tracker;
  } catch (error) {
    console.error("Failed to remove tracker by group ID:", error);
    return false;
  }
};
// #endregion removeTrackerByGroupID

// #region clearCompletedTrackers
// Clear all completed trackers
// Output: Promise<void>
export const clearCompletedTrackers = async (): Promise<void> => {
  const trackers = await getAllTrackers();
  const completedTrackers = trackers.filter((tracker) => tracker.completed);
  await Promise.all(
    completedTrackers.map((tracker) => removeTrackerByGroupID(tracker.groupID))
  );
};
// #endregion clearCompletedTrackers

// // Local forage to set preference for creating a new session, a true and false
// // Output: Promise<void>
// export const setNewSession = async (value: boolean): Promise<void> => {
//   await localforage.setItem("createNewSession", value);
// };

// // Local forage to get preference for creating a new session
// // Output: Promise<boolean> - The preference for creating a new session
// export const getNewSession = async (): Promise<boolean> => {
//   return (await localforage.getItem<boolean>("createNewSession")) || false;
// };

// #region syncDocs
// Sync docs, if it doesn't exist in either queue, change the tracker status to Invalid
// Input: All trackers: DocumentQueueTracker[]
// Output: Promise<void>
export const syncDocs = async (): Promise<void> => {
  // // Get all docs in the queue
  // const docQueue = await getDocumentQueue();
  // // Get all docs in the stale queue
  // const staleQueue = await getStaleQueue();
  // Get all trackers
  // const trackers = await getAllTrackers();
  // // Process all trackers
  // await Promise.all(
  //   trackers.map(async (tracker) => {
  //     // const isDocInDocQueue = docQueue.some(
  //     //   (doc) => doc.uuidGroup === tracker.groupID
  //     // );
  //     // const isDocInStaleQueue = staleQueue.some(
  //     //   (doc) => doc.uuidGroup === tracker.groupID
  //     // );
  //     // const isDocInInvalidQueue = invalidQueue.some(
  //     //   (doc) => doc.uuidGroup === tracker.groupID
  //     // );
  //     // // Change the tracker status to Invalid if the doc is not in either queue
  //     // if (!isDocInDocQueue && !isDocInStaleQueue && !tracker.completed) {
  //     //   tracker.isInvalid = true;
  //     // } else {
  //     //   tracker.isInvalid = false;
  //     // }
  //     // Update the trackers
  //     await addGroupIDToTracker(tracker.groupID, tracker);
  //   })
  // );
};

// #endregion syncDocs

// #region addToInvalidQueue
export const addToInvalidQueue = async (
  doc: StoredDocument[]
): Promise<boolean> => {
  try {
    const invalidQueue =
      (await localforage.getItem<StoredDocument[]>("invalidQueue")) || [];
    invalidQueue.push(...doc);
    await localforage.setItem("invalidQueue", invalidQueue);
    return invalidQueue.includes(doc[0]);
  } catch (error) {
    console.error("Failed to add document to stale queue:", error);
    return false;
  }
};
// #endregion addToInvalidQueue

// #region getInvalidQueue
export const getInvalidQueue = async (): Promise<StoredDocument[]> => {
  return (await localforage.getItem<StoredDocument[]>("invalidQueue")) || [];
};
// #endregion getInvalidQueue

// #region clearInvalidQueue
export const clearInvalidQueue = async (): Promise<void> => {
  await localforage.removeItem("invalidQueue");
};
// #endregion clearInvalidQueue

// #region getInvalidQueueByGroupID
export const getInvalidQueueByGroupID = async (
  groupID: string
): Promise<StoredDocument[]> => {
  const invalidQueue = await getInvalidQueue();
  return invalidQueue.filter((doc) => doc.uuidGroup === groupID);
};
// #endregion getInvalidQueueByGroupID

// #region removeDocFromInvalidQueue
export const removeDocFromInvalidQueue = async (
  docID: string
): Promise<boolean> => {
  try {
    const invalidQueue = await getInvalidQueue();
    const updatedQueue = invalidQueue.filter((doc) => doc.id !== docID);
    await localforage.setItem("invalidQueue", updatedQueue);
    return !updatedQueue.some((doc) => doc.id === docID);
  } catch (error) {
    console.error("Failed to remove document from invalid queue:", error);
    return false;
  }
};
// #endregion removeDocFromInvalidQueue

// #region getUnUploadedDocumentQueue
export const getUnUploadedDocumentQueue = async (): Promise<
  StoredDocument[]
> => {
  const documentQueue = await getDocumentQueue();
  return documentQueue.filter((doc) => doc.status !== "uploaded");
};
// #endregion getUnUploadedDocumentQueue

// #region removeDocsByGroupIDInDocQueue
export const removeDocsByGroupIDInDocQueue = async (
  groupID: string
): Promise<boolean> => {
  try {
    const documentQueue = await getDocumentQueue();
    const updatedQueue = documentQueue.filter(
      (doc) => doc.uuidGroup !== groupID
    );
    await localforage.setItem("documentQueue", updatedQueue);
    return !updatedQueue.some((doc) => doc.uuidGroup === groupID);
  } catch (error) {
    console.error(
      `Failed to remove documents by groupID ${groupID} in document queue:`,
      error
    );
    return false;
  }
};
// #endregion removeDocsByGroupIDInDocQueue

// #region removeDocsByGroupIDInStaleQueue
export const removeDocsByGroupIDInStaleQueue = async (
  groupID: string
): Promise<boolean> => {
  try {
    const staleQueue = await getStaleQueue();
    const updatedQueue = staleQueue.filter((doc) => doc.uuidGroup !== groupID);
    await localforage.setItem("staleQueue", updatedQueue);
    return !updatedQueue.some((doc) => doc.uuidGroup === groupID);
  } catch (error) {
    console.error(
      `Failed to remove documents by groupID ${groupID} in stale queue:`,
      error
    );
    return false;
  }
};

// #endregion removeDocsByGroupIDInStaleQueue
// #region isGroupIDInInvalidQueue
export const removeDocsByGroupIDInInvalidQueue = async (
  groupID: string
): Promise<boolean> => {
  try {
    const invalidQueue = await getInvalidQueue();
    const updatedQueue = invalidQueue.filter(
      (doc) => doc.uuidGroup !== groupID
    );
    await localforage.setItem("invalidQueue", updatedQueue);
    return !updatedQueue.some((doc) => doc.uuidGroup === groupID);
  } catch (error) {
    console.error(
      `Failed to remove documents by groupID ${groupID} in invalid queue:`,
      error
    );
    return false;
  }
};
// #endregion removeDocsByGroupIDInInvalidQueue

export const removeAllDocsByGroupID = async (
  groupID: string
): Promise<boolean> => {
  try {
    await removeDocsByGroupIDInDocQueue(groupID);
    await removeDocsByGroupIDInStaleQueue(groupID);
    await removeDocsByGroupIDInInvalidQueue(groupID);
    return true;
  } catch (error) {
    console.error(
      `Failed to remove all documents by groupID ${groupID}:`,
      error
    );
    return false;
  }
};
