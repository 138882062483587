import { apiFetcher } from "@/services/API.service";
import { StoredDocument } from "@/types";

// Upload to BE
export async function uploadDocument(doc: StoredDocument): Promise<
  | {
      rStatus: "success";
    }
  | {
      rStatus: "error";
      error:
        | "network"
        | "badRequest"
        | "imageProcessing"
        | "serviceUnavailable"
        | (string & {});
    }
> {
  try {
    const response = await apiFetcher({
      route: "/upload/image",
      method: "POST",
      options: {
        body: {
          image: doc.documentB64,
          uuid: doc.uuidGroup,
        },
      },
    });

    if (
      response.status === 200 ||
      response.status === 204 ||
      response.status === 202
    ) {
      return { rStatus: "success" };
    }

    console.log("uploadDocument response:", response);

    if (response.status === 0) {
      console.error("uploadDocument: Network error");
      return {
        rStatus: "error",
        error: "network",
      };
    }

    if (response.status === 400) {
      console.error("uploadDocument: Bad Request");
      return {
        rStatus: "error",
        error: "badRequest",
      };
    }

    if (response.status === 406) {
      console.error("uploadDocument: Image could not be processed");
      return {
        rStatus: "error",
        error: "imageProcessing",
      };
    }

    if (response.status === 503) {
      console.error("uploadDocument: Service Unavailable");
      return {
        rStatus: "error",
        error: "serviceUnavailable",
      };
    }

    return {
      rStatus: "error",
      error: "Incorrect response. Please contact support",
    };
  } catch (error) {
    console.error("uploadDocument:", error);
    return {
      rStatus: "error",
      error: "Failed to upload document",
    };
  }
}

// Submissions completed for group
export async function sendCompleteConfirmation(groupID: string): Promise<
  | {
      rStatus: "success";
    }
  | {
      rStatus: "error";
    }
> {
  try {
    const response = await apiFetcher({
      route: "/form/user/completed",
      method: "POST",
      options: {
        body: {
          uuid: groupID,
        },
      },
    });

    if (
      response.status === 200 ||
      response.status === 202 ||
      response.status === 204
    ) {
      return { rStatus: "success" };
    }

    if (response.status === 0) {
      return { rStatus: "error" };
    }

    return { rStatus: "error" };
  } catch (error) {
    console.error("getCompletedSubmissions:", error);
    return { rStatus: "error" };
  }
}
