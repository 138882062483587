import "@/App.css";
import LoginPage from "@/pages/login.page";
import { titleUpdate } from "@/utils/utils";
import { useEffect, useLayoutEffect, useState } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router";
import PWAPrompt from "./components/PWAPrompt/PWAPrompt.component";
import { ROUTE_HOME } from "./constants/routes.constants";
import { NetworkStatusProvider } from "./contexts/NetworkStatusProvider";
import AllForms from "./pages/all.forms.page";
import FormPage from "./pages/form.page";
import HomePage from "./pages/home.page";
import PageNotFound from "./pages/pageNotFound.page";
import ProtectedRoute from "./services/ProtectedRoute";
import { ToastProvider } from "./shadcn/components/ui/toast";

function App() {
  const [singleTab, setSingleTab] = useState<"true" | "false">("false");
  const [loading, setLoading] = useState(true); // Add loading state

  const TitleUpdater = ({ title }: { title?: string }) => {
    // Updates the page title based on the current route
    useLayoutEffect(() => {
      titleUpdate(title);
    }, [title]);
    return null;
  };

  useEffect(() => {
    function messageListener(e: MessageEvent) {
      const { data } = e;
      console.log(data);

      if (data === "ping") {
        bc.postMessage("pong");
        setSingleTab("true");
        setLoading(false); // Set loading to false when ping is received
        clearTimeout(timeout); // Cancel the timeout if a message is received
      }

      if (data === "pong") {
        console.error("Another tab is already open");
        setSingleTab("false");
        setLoading(false); // Set loading to false when pong is received
        clearTimeout(timeout); // Cancel the timeout if a message is received
      }
    }

    console.log("Checking for single tab");

    const bc = new BroadcastChannel("single_tab");

    bc.addEventListener("message", messageListener);

    bc.postMessage("ping");

    // Set a timeout to handle the case where no other tab responds
    const timeout = setTimeout(() => {
      setLoading(false);
      setSingleTab("true");
    }, 100); // Adjust the timeout duration as needed

    return () => {
      bc.removeEventListener("message", messageListener);
      clearTimeout(timeout); // Clear the timeout on cleanup
    };
  }, []);

  if (loading) {
    return null; // Return null while loading
  }

  return singleTab === "false" ? (
    <div className="container mx-auto my-auto flex items-center justify-center h-screen">
      <div className="text-center">
        <h1 className="text-3xl font-bold text-primary">
          Another tab is already open
        </h1>
        <p className="text-xl font-bold">
          Please only have one tab open at a time
        </p>
      </div>
    </div>
  ) : (
    <div className="App h-svh w-svw overflow-hidden">
      <ToastProvider>
        <NetworkStatusProvider>
          <Router>
            <PWAPrompt />
            <Routes>
              <Route
                path="login"
                element={
                  <>
                    <TitleUpdater title="Login" />
                    <LoginPage />
                  </>
                }
              />
              <Route element={<ProtectedRoute />}>
                <Route
                  path="/home/:formID?"
                  element={
                    <>
                      <TitleUpdater title="Home" />
                      <HomePage />
                    </>
                  }
                />
                <Route
                  path="/all/forms"
                  element={
                    <>
                      <TitleUpdater title="All Forms" />
                      <AllForms />
                    </>
                  }
                />
                <Route
                  path="/form/:uuid"
                  element={
                    <>
                      <TitleUpdater title="Form" />
                      <FormPage />
                    </>
                  }
                />
                <Route path="*" element={<PageNotFound />} />
              </Route>
              <Route path="/" element={<Navigate to={ROUTE_HOME} replace />} />
            </Routes>
          </Router>
        </NetworkStatusProvider>
      </ToastProvider>
    </div>
  );
}

export default App;
