import { ROUTE_LOGIN_OUT } from "@/constants/routes.constants";
import { NetworkStatusContext } from "@/contexts/NetworkStatusContext";
import UserProvider from "@/contexts/UserContext/userProvider";
import { sendLoginRequest } from "@/models/auth.model";
import {
  decodeLoginDetails,
  getLocStoreIsLoggedIn,
  getLocStoreUserDetails,
  removeLocStoreUserDetails,
} from "@/utils/localAuth.util";
import {
  getTokensLocalStorage,
  isTokenAboutToExpire,
  isTokenExpired,
} from "@/utils/tokenHandler.util";
import React, { useContext, useLayoutEffect, useState } from "react";
import { Navigate, Outlet } from "react-router";

export const ProtectedRoute: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCheckComplete, setIsCheckComplete] = useState(false);
  const { isApiOnline, setIsAuthenticated } = useContext(NetworkStatusContext);

  async function checkUserLoginStatus() {
    const lsIsLoggedIn = getLocStoreIsLoggedIn();
    if (!lsIsLoggedIn || lsIsLoggedIn !== "true") {
      setIsLoggedIn(false);
      setIsCheckComplete(true);
      console.log("User not logged in");
      return;
    }

    const userDetails = getLocStoreUserDetails();
    if (!userDetails) {
      setIsLoggedIn(false);
      setIsCheckComplete(true);
      console.log("No user details found");
      return;
    }

    const decodedUser = decodeLoginDetails(userDetails);

    if (
      !decodedUser ||
      decodedUser.username === "" ||
      decodedUser.password === ""
    ) {
      setIsLoggedIn(false);
      setIsCheckComplete(true);
      removeLocStoreUserDetails();
      console.log("Invalid user details");
      return;
    }

    const token = await getTokensLocalStorage();
    if (!token || !token.refreshToken) {
      setIsLoggedIn(false);
      setIsCheckComplete(true);
      setIsAuthenticated(false);
      console.log("No refreshToken found");
      return;
    }
    // If the refresh token is expired, try to refresh it
    const isExpiredToken = isTokenExpired(token.refreshToken);
    // 2 Minutes before the token expires
    const isAboutToExpire = isTokenAboutToExpire(token.refreshToken);
    if (isExpiredToken || isAboutToExpire) {
      // Only make the call if isApiOnline
      if (isApiOnline) {
        const res = await sendLoginRequest(
          decodedUser.username,
          decodedUser.password
        );
        if (res.rStatus === "success") {
          setIsAuthenticated(true);
          console.log("Error refreshing token");
        } else {
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
    } else {
      // setIsAuthenticated(true);
    }

    setIsLoggedIn(true);
    setIsCheckComplete(true);
  }

  async function checkUserTokens() {
    const userDetails = getLocStoreUserDetails();

    if (!userDetails) {
      console.log("No user details found");
      setIsLoggedIn(false);
      return;
    }

    const decodedUser = decodeLoginDetails(userDetails);

    if (
      !decodedUser ||
      decodedUser.username === "" ||
      decodedUser.password === ""
    ) {
      console.log("Invalid user details");
      removeLocStoreUserDetails();
      setIsLoggedIn(false);
      return;
    }

    // Only send login request if isApiOnline && tokens are expired or about to expire
    const token = await getTokensLocalStorage();
    const isExpiredToken =
      token && token.refreshToken ? isTokenExpired(token.refreshToken) : true;
    const isAboutToExpire =
      token && token.refreshToken
        ? isTokenAboutToExpire(token.refreshToken)
        : true;

    if (!token || !token.refreshToken || isExpiredToken || isAboutToExpire) {
      const loginRes = await sendLoginRequest(
        decodedUser.username,
        decodedUser.password
      );
      if (loginRes.rStatus === "success") {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(true);
    }
  }

  useLayoutEffect(() => {
    checkUserLoginStatus();
  }, []);

  useLayoutEffect(() => {
    if (isApiOnline) {
      const checkTokens = () => {
        checkUserTokens();
      };

      // Run initially
      checkTokens();

      // Set up interval to run every minute
      const interval = setInterval(checkTokens, 60 * 1000);

      // Cleanup function to clear the interval when component unmounts or isApiOnline changes
      return () => clearInterval(interval);
    } else {
      setIsAuthenticated(false);
    }
  }, [isApiOnline, setIsAuthenticated]);

  return !isCheckComplete ? null : isLoggedIn ? (
    <UserProvider>
      <Outlet />
    </UserProvider>
  ) : (
    <Navigate to={ROUTE_LOGIN_OUT} />
  );
};

export default ProtectedRoute;
