import { z } from "zod";

// #region Fetch Response and JWT Types
export type FetchResponse = {
  status: number;
  data: unknown;
  error?: string;
};

export type JWT = {
  refreshToken: string;
};

export type GenericProviderState<T> = {
  data: T | null;
  loading: boolean;
  error: "unauthorized" | "network_error" | (string & {}) | null;
};

// #endregion Fetch Response and JWT Types

export const captureInfoSchema = z
  .object({
    id: z.number().nullable(), // Allow null values for numbers
    uuid: z.string().nullable(), // Allow null values for strings
    name: z.string().nullable(),
    surname: z.string().nullable(),
    address: z.string().nullable(),
    date_of_birth: z.string().nullable(),
    nationality: z.string().nullable(),
    phone_number: z.string().nullable(),
    id_number: z.string().nullable(),
    job_title: z.string().nullable(),
    rsa_based: z.boolean().nullable(), // Allow null values for booleans
    citizenship: z.boolean().nullable(),
    bp: z.boolean().nullable(),
    skills_level: z.string().nullable(),
    local_communities: z.boolean().nullable(),
    youth: z.boolean().nullable(),
    disability: z.boolean().nullable(),
    women: z.boolean().nullable(),
  })
  .transform((data) => ({
    ...data,
    id: data.id ?? 0, // Transform null to default value
    uuid: data.uuid ?? "", // Transform null to default value
    name: data.name ?? "",
    surname: data.surname ?? "",
    address: data.address ?? "",
    date_of_birth: data.date_of_birth ?? "",
    nationality: data.nationality ?? "",
    phone_number: data.phone_number ?? "",
    id_number: data.id_number ?? "",
    job_title: data.job_title ?? "",
    rsa_based: data.rsa_based ?? false, // Transform null to default value
    citizenship: data.citizenship ?? false,
    bp: data.bp ?? false,
    skills_level: data.skills_level ?? "",
    local_communities: data.local_communities ?? false,
    youth: data.youth ?? false,
    disability: data.disability ?? false,
    women: data.women ?? false,
  }));

export const storedDocumentSchema = z.object({
  id: z.string(),
  uuidGroup: z.string(),
  status: z.enum(["pending", "uploaded", "failed", "stale", "review"]),
  documentB64: z.string(),
  documentName: z.string(),
  // documentForm: captureInfoSchema,
  functionName: z.string(),
});

export type StoredDocument = z.infer<typeof storedDocumentSchema>;

export type CaptureInfo = z.infer<typeof captureInfoSchema>;

export const UserInfoSchema = z.object({
  username: z.string(),
  admin: z.boolean(),
});

export type UserInfo = z.infer<typeof UserInfoSchema>;

export type InfoCaptureState = {
  loading: boolean;
  processingBatch: boolean;
  error: string | null;
  groupID: string;
  stale: boolean;
  queueLength: number;
  submitting: boolean;
};

export type InfoCaptureAction =
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_ERROR"; payload: string | null }
  | { type: "SET_GROUPID"; payload: string }
  | { type: "SET_PROCESSING_BATCH"; payload: boolean }
  | { type: "SET_STALE"; payload: boolean }
  | { type: "SET_QUEUE_LENGTH"; payload: number }
  | { type: "SET_SUBMITTING"; payload: boolean };

export const SuperAdminReportsSchema = z.object({
  name: z.string().nullable(),
  surname: z.string().nullable(),
  nationality: z.string().nullable(),
  idNumber: z.string().nullable().optional(),
  uuid: z.string(),
  created_at: z.string().nullable(),
  uploader: z.string().nullable(),
});

export type SuperAdminReports = z.infer<typeof SuperAdminReportsSchema>;

export const docListSchema = z.object({
  id: z.number(),
  uuid: z.string(),
  processing_status: z.string(),
  created_at: z.string(),
});

export type DocList = z.infer<typeof docListSchema>;

// Document Processing Tracking

export const DocumentQueueTrackerSchema = z.object({
  total: z.number(),
  uploaded: z.number(),
  stale: z.number(),
  invalid: z.number(),
  completed: z.boolean(),
  groupID: z.string(),
  // isInvalid: z.boolean().optional(),
});

export type DocumentQueueTracker = z.infer<typeof DocumentQueueTrackerSchema>;
