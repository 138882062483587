import { ROUTE_LOGIN_OUT } from "@/constants/routes.constants";
import { InfoCaptureContext } from "@/contexts/InfoCaptureContext";
import { UserContext } from "@/contexts/UserContext/userContext";
import { Button } from "@/shadcn/components/ui/button";
import { BadgeCheck, LogOut, ShieldAlert, Wifi, WifiOff } from "lucide-react";
import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid"; // Import uuid

import DocumentTracker from "@/components/DocumentTrackerSheet/documentTracker.component";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/shadcn/components/ui/tooltip";
import { useLocation, useNavigate } from "react-router";
import { NetworkStatusContext } from "../contexts/NetworkStatusContext";

type FooterProps = {};

const Footer: React.FC<FooterProps> = () => {
  const { user } = useContext(UserContext);
  const { isOnline, isApiOnline, isAuthenticated } =
    useContext(NetworkStatusContext);
  const { dispatch, state } = useContext(InfoCaptureContext);

  const nav = useNavigate();
  const location = useLocation();

  function logout() {
    window.location.href = ROUTE_LOGIN_OUT;
  }

  return (
    <footer className="flex justify-between items-center p-3 b bg-neutral-300">
      <div className="flex gap-2">
        {isOnline ? (
          <TooltipProvider>
            <Tooltip delayDuration={150}>
              <TooltipTrigger>
                <Wifi
                  className={`w-4 h-4 cursor-default ${
                    isApiOnline ? "!text-green-600" : "!text-orange-500"
                  } `}
                />
              </TooltipTrigger>
              <TooltipContent>
                <p>{`Connection is ${isApiOnline ? "Online" : "Offline"}`}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : (
          <TooltipProvider delayDuration={150}>
            <Tooltip>
              <TooltipTrigger>
                <WifiOff className="w-4 h-4 !text-red-600 cursor-default" />
              </TooltipTrigger>
              <TooltipContent>
                <p>Internet is Offline</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
        {isAuthenticated ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <BadgeCheck className="w-4 h-4 !text-green-600 cursor-default" />
              </TooltipTrigger>
              <TooltipContent>
                <p>Authenticated</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <ShieldAlert className="w-4 h-4 !text-red-600 cursor-default" />
              </TooltipTrigger>
              <TooltipContent>
                <p>Not Authenticated</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      <div className="flex gap-2">
        {user.admin ? (
          <>{/* <Button>Reports</Button> */}</>
        ) : (
          <>
            {location.pathname === "/home" ? (
              <Button
                onClick={() => {
                  console.log("clicked");
                  const groupID = uuidv4();
                  dispatch({ type: "SET_GROUPID", payload: groupID });
                }}
                disabled={state.submitting}
              >
                New Session
              </Button>
            ) : (
              <Button
                onClick={() => {
                  nav("/home");
                }}
              >
                Upload
              </Button>
            )}

            <Button
              onClick={() => {
                nav("/all/forms");
              }}
              disabled={!isOnline || !isApiOnline || !isAuthenticated}
            >
              Forms
            </Button>
          </>
        )}
      </div>

      {/* Tracker Sheet */}
      <div className="flex gap-2">
        <DocumentTracker />

        {/* Logout */}
        <div>
          <Button
            variant={"ghost"}
            size={"icon"}
            onClick={logout}
            className="hover:text-white"
          >
            <LogOut />
          </Button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
