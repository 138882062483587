import LoginBackgroundImage from "@/assets/images/pexels-singkham-178541-1108572.webp";
import Login from "@/components/Login/login.component";
import { removeLocStoreIsLoggedIn } from "@/utils/localAuth.util";
import React, { useEffect } from "react";

type LoginPageProps = {
  // TODO: add props here
};

const LoginPage: React.FC<LoginPageProps> = (props) => {
  useEffect(() => {
    removeLocStoreIsLoggedIn();
  }, []);

  return (
    <div>
      <div className="flex justify-center align-middle h-svh items-center bg-neutral-900">
        <img
          src={LoginBackgroundImage}
          alt=""
          className="w-full h-full object-cover fixed top-0 left-0 z-0 opacity-30"
        />
        <Login />
      </div>
    </div>
  );
};

export default LoginPage;
