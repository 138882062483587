import { fetchForms } from "@/models/documents.model";
import { DocList, StoredDocument } from "@/types";
import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router";
import CustomTextLoader from "../CustomTextLoader/customTextLoader.componnet";
import { DataTable } from "../DataTable/dataTable.component";
import { RefreshCcw } from "lucide-react";
import { Button } from "@/shadcn/components/ui/button";

type AllFormsListProps = {};

const AllFormsList: React.FC<AllFormsListProps> = () => {
  const [forms, setForms] = useState<DocList[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const columns: ColumnDef<DocList>[] = [
    {
      id: "uuid",
      header: "UUID",
      accessorKey: "uuid",
      cell: ({ row }) => (
        <div className="flex flex-row justify-start w-full">
          {row.original.uuid.split("-")[0]}
        </div>
      ),
    },

    {
      id: "created_date",
      header: "Created Date",
      accessorKey: "created_at",
      cell: ({ row }) => {
        const formattedDate = format(
          new Date(row.original.created_at),
          "d MMMM yyyy"
        );
        return (
          <div className="flex flex-row justify-start w-full">
            {formattedDate}
          </div>
        );
      },
    },
    {
      id: "created_time",
      header: "Created Time",
      accessorKey: "created_at",
      cell: ({ row }) => {
        const formattedTime = format(
          new Date(row.original.created_at),
          "HH:mm"
        );
        return (
          <div className="flex flex-row justify-start w-full">
            {formattedTime}
          </div>
        );
      },
    },
    {
      id: "processing_status",
      header: "Status",
      accessorKey: "processing_status",
      cell: ({ row }) => (
        <div className="flex flex-row justify-start w-full capitalize">
          {row.original.processing_status}
        </div>
      ),
    },
    // {
    //   id: "resume_session",
    //   header: () => <div>Resume</div>,
    //   cell: ({ row }) => (
    //     <div className="flex flex-row w-full">
    //       <button
    //         onClick={() => navigate(`/home/${row.original.uuid}`)}
    //         className="text-blue-500 underline "
    //       >
    //         Resume Session
    //       </button>
    //     </div>
    //   ),
    // },

    {
      id: "actions",
      header: () => <div className="">Actions</div>,
      cell: ({ row }) => {
        return (
          <div className="flex flex-row w-full">
            <div>
              <button
                onClick={() => navigate(`/form/${row.original.uuid}`)}
                className={`text-right underline ${
                  row.original.processing_status !== "completed"
                    ? "text-gray-500 cursor-not-allowed"
                    : "text-blue-500"
                }`}
                disabled={row.original.processing_status !== "completed"}
              >
                View
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  async function fetchFormsRes() {
    setLoading(true);
    setError("");
    const res = await fetchForms();
    setLoading(false);

    if (res.rStatus === "error") {
      setError(res.error);
      return;
    }

    setForms(
      res.rData.sort((a, b) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      })
    );
  }

  useLayoutEffect(() => {
    fetchFormsRes();
  }, []);

  return (
    <>
      <h1 className="text-3xl font-bold text-center text-primary mt-3">
        Forms
      </h1>
      <div className="flex w-full justify-end pe-5">
        <Button
          onClick={fetchFormsRes}
          className="bg-primary text-white rounded-md"
        >
          <RefreshCcw className="w-10 h-10" />
        </Button>
      </div>
      <div className="flex-grow flex items-center flex-col justify-start w-full p-5 overflow-auto">
        {loading ? (
          <CustomTextLoader
            texts={["Collecting data", "Sorting data", "Generating report"]}
          />
        ) : error ? (
          <div className="text-red-500">Error: {error}</div>
        ) : forms.length === 0 ? (
          <div>No forms available</div>
        ) : (
          <div className="w-full max-h-[500px]">
            <DataTable columns={columns} data={forms} />
          </div>
        )}
      </div>
    </>
  );
};

export default AllFormsList;
