import { FetchResponse, JWT } from "@/types";
import { jwtDecode, JwtPayload } from "jwt-decode";

import { ROUTE_LOGIN_OUT } from "@/constants/routes.constants";
import { fetchJwt } from "@/models/auth.model";
import Swal from "sweetalert2";

const REFRESH_TOKEN = "refresh-token";

// #region isTokenExpired
// Check if the jwt is not expired
export function isTokenExpired(token: string): boolean {
  try {
    // To decode and get the payload without specifying options
    const decoded: JwtPayload = jwtDecode(token);

    if (!decoded.exp) {
      return true;
    }

    // log formatted date
    // console.log(
    //   new Date(decoded.exp * 1000).toLocaleString("UTC", {
    //     timeZone: "UTC",
    //   })
    // );
    // remainingTimeHours, remainingTimeMinutes, remainingTimeSeconds

    const remainingTimeHours = Math.floor(
      (decoded.exp * 1000 - Date.now()) / (1000 * 60 * 60)
    );
    const remainingTimeMinutes = Math.floor(
      ((decoded.exp * 1000 - Date.now()) / (1000 * 60)) % 60
    );
    const remainingTimeSeconds = Math.floor(
      ((decoded.exp * 1000 - Date.now()) / 1000) % 60
    );
    // totalTimeRemaining : 00:00:00
    const totalTimeRemaining = `${remainingTimeHours}:${remainingTimeMinutes}:${remainingTimeSeconds}`;
    if (decoded.exp < Date.now() / 1000) {
      console.log("Token expired " + totalTimeRemaining);

      return true;
    } else {
      // console.log("Token valid " + totalTimeRemaining);
      return false;
    }
  } catch (err) {
    console.error(err);
    return true;
  }
}
// #endregion isTokenExpired

// #region isTokenAboutToExpire
// Check if the jwt is about to expire (Tentative Timing: 30 seconds)
export function isTokenAboutToExpire(token: string): boolean {
  try {
    const decoded: JwtPayload = jwtDecode(token);

    // log formatted date
    // console.log(
    //   new Date(decoded.exp * 1000).toLocaleString("UTC", {
    //     timeZone: "UTC",
    //   })
    // );

    // convert remaining time to second from epoch
    const remainingTimeSeconds = decoded.exp
      ? Math.floor((decoded.exp * 1000 - Date.now()) / 1000)
      : 0;

    // console.log("remainingTimeSeconds: " + remainingTimeSeconds);

    // if remaining timeSeconds is less than or equal to 120 seconds, then return true
    if (remainingTimeSeconds <= 120) {
      // console.log(
      //   "Token is about to expire in " + remainingTimeSeconds + " seconds"
      // );
      return true;
    }

    // totalTimeRemaining : 00:00:00
    const totalTimeRemaining = `${remainingTimeSeconds}`;
    if (!decoded.exp || decoded.exp < Date.now() / 1000) {
      console.log("Token expired " + totalTimeRemaining);

      return true;
    } else {
      // console.log("Token valid " + totalTimeRemaining);
      return false;
    }
  } catch (err) {
    console.error(err);
    return true;
  }
}
// #endregion isTokenAboutToExpire

// #region refreshTokenCheckRedirect
// Checks if the refresh token exists in local storage, redirects accordingly
export function refreshTokenCheckRedirect() {
  const storedRefreshToken = localStorage.getItem(REFRESH_TOKEN);
  console.log("storedRefreshToken: ", storedRefreshToken);

  // Check if the refresh token exists in local storage
  if (storedRefreshToken) {
    // console.log("Refresh token exists in local storage:", storedRefreshToken);

    // Check if the refresh token is expired
    if (isTokenExpired(storedRefreshToken)) {
      console.log("Refresh token expired");
      // Redirect to login page after 5 seconds
      window.location.href = ROUTE_LOGIN_OUT;
    } else {
      console.log("Refresh token is not expired");
      // Proceed with further actions if needed
      window.location.href = "/";
    }
  } else {
    console.log("Refresh token does not exist in local storage");
    // Redirect to login page or handle the absence of token

    window.location.href = ROUTE_LOGIN_OUT;
  }
}
// #endregion refreshTokenCheckRedirect

// #region setTokensLocalStorage
// Set refresh and auth tokens in local storage, from response
export async function setTokensLocalStorage(res: FetchResponse) {
  console.log("HIT SET TOKENS LOCAL STORAGE");
  console.log(res);
  try {
    if (
      res &&
      res.data &&
      typeof res.data === "object" &&
      "refreshToken" in res.data &&
      typeof res.data.refreshToken === "string"
    ) {
      const refreshToken = res.data.refreshToken;
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
    } else {
      console.log("setTokensLocalStorage: Invalid response object");
    }
  } catch (error) {
    console.error(`setTokensLocalStorage: ${error}`);
  }
}
// #endregion setTokensLocalStorage

// #region setMfaTokensLocalStorage
// Set refresh and auth tokens in local storage, from response
export async function setMfaTokensLocalStorage(res: FetchResponse) {
  try {
    if (
      res &&
      res.data &&
      typeof res.data === "object" &&
      "mfaConfirmResponse" in res.data &&
      res.data.mfaConfirmResponse &&
      typeof res.data.mfaConfirmResponse === "object" &&
      "refreshToken" in res.data.mfaConfirmResponse &&
      typeof res.data.mfaConfirmResponse.refreshToken === "string"
    ) {
      const refreshToken = res.data.mfaConfirmResponse.refreshToken;

      // console.log(`authToken: ${authToken}`);
      // console.log(`refreshToken: ${refreshToken}`);

      localStorage.setItem(REFRESH_TOKEN, refreshToken);
    } else {
      console.error("setTokensLocalStorage: Invalid response object");
    }
  } catch (error) {
    console.error(`setTokensLocalStorage: ${error}`);
  }
}
// #endregion setMfaTokensLocalStorage

// #region getTokensLocalStorage
// Get refresh and auth tokens from local storage
export async function getTokensLocalStorage(): Promise<{
  refreshToken: string | null;
}> {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  return { refreshToken };
}
// #endregion getTokensLocalStorage

// #region requestAndSetTokens
// Request and Set the JWTs in local storage if the request was successful and is available
export async function requestAndSetTokens(newJwt: JWT) {
  try {
    const res = await fetchJwt(newJwt);
    console.log(res);
    if (!res.error) {
      // console.log(res.data);
      // Set the JWTs in local storage if the request was successful and is available
      if (res.data) {
        await setTokensLocalStorage(res);
      }
    } else {
      console.log(res.error);
      // direct to login
      Swal.fire({
        title: "Error!",
        text: "We were unable to authenticate you. Please login again",
        icon: "error",
      });
    }
  } catch (error) {
    console.error(error);
    Swal.fire({
      title: "Error!",
      text: "We were unable to authenticate you. Please login again",
      icon: "error",
    });
  }
}
// #endregion requestAndSetTokens

// #region requestAndSetMfaTokens
// Request and Set the JWTs in local storage if the request was successful and is available
// USE CASE:
// User went through MFA, encoded string (Email Pw code verified)
// export async function requestAndSetMfaTokens(
//   encodedMFAConfirmString: string
// ): Promise<string | undefined> {
//   console.log("HIT REQUEST CONFIRM MFA");
//   try {
//     const res = await mfaLogin(encodedMFAConfirmString);
//     console.log(res);
//     if (!res.error && res.status === 200 && res.data) {
//       console.log(res.data);
//       // Set the JWTs in local storage if the request was successful and is available
//       await setMfaTokensLocalStorage(res);
//       if (res.data) {
//         console.log(res.data);
//         return res.status.toString();
//       }
//       // Server Error
//     } else if (res.status === 500) {
//       console.log(res.error);
//       return "An error occurred on the server. Please try again later.";
//       // Client Error
//     } else {
//       return res.error;
//     }
//   } catch (error) {
//     console.error(error);
//   }
// }
// #endregion requestAndSetMfaTokens

// #region deleteTokensLocalStorage
// Delete refresh and auth tokens from local storage
export async function deleteTokensLocalStorage() {
  localStorage.removeItem(REFRESH_TOKEN);
}
