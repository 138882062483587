import { checkAlive } from "@/models/auth.model";
import React, { ReactNode, useEffect, useState } from "react";
import { NetworkStatusContext } from "./NetworkStatusContext";

interface NetworkStatusProviderProps {
  children: ReactNode;
}

export const NetworkStatusProvider: React.FC<NetworkStatusProviderProps> = ({
  children,
}) => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isApiOnline, setIsApiOnline] = useState<boolean>(false);

  useEffect(() => {
    const updateOnlineStatus = () => {
      if (!navigator.onLine) {
        setIsOnline(false);
        setIsApiOnline(false);
      }
      if (navigator.onLine) {
        console.log("Internet is back online");
        setIsOnline(true);
      } else {
        console.log("Internet is offline");
        setIsOnline(false);
        setIsApiOnline(false);
      }
    };

    // Add event listeners for network status changes
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    const checkNetworkStatus = async () => {
      if (!isOnline) {
        return;
      }
      const isOnlinePromise = checkAlive();
      const timeoutPromise = new Promise<boolean>((resolve) =>
        setTimeout(() => resolve(false), 30000)
      );

      const resIsOnline = await Promise.race([isOnlinePromise, timeoutPromise]);
      if (!resIsOnline) {
        // console.log("Network API is down");
        setIsApiOnline(false);
      } else {
        // console.log("Network API is up");
        setIsApiOnline(true);
      }
    };

    // Run immediately
    checkNetworkStatus();

    // Set interval to recheck
    const interval = setInterval(checkNetworkStatus, 15000);

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, [isOnline]);

  return (
    <NetworkStatusContext.Provider
      value={{ isOnline, isApiOnline, isAuthenticated, setIsAuthenticated }}
    >
      {children}
    </NetworkStatusContext.Provider>
  );
};
