import {
  clearStaleQueue,
  getUnUploadedDocumentQueue,
  syncDocuments,
} from "@/utils/documentLoader.util";
import React, { useContext, useEffect, useReducer } from "react";
import {
  InfoCaptureContext,
  infoCaptureReducer,
  initialState,
} from "./InfoCaptureContext";
import { NetworkStatusContext } from "./NetworkStatusContext";

interface InfoCaptureProviderProps {
  children: React.ReactNode;
}

const InfoCaptureProvider: React.FC<InfoCaptureProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(infoCaptureReducer, initialState);

  const { isAuthenticated, isApiOnline } = useContext(NetworkStatusContext);

  // Check queue length every 2.5 seconds and set it in state
  useEffect(() => {
    const interval = setInterval(async () => {
      const queue = await getUnUploadedDocumentQueue();
      dispatch({ type: "SET_QUEUE_LENGTH", payload: queue.length });
    }, 2500);
    return () => clearInterval(interval);
  }, []);

  // Sync Documents (upload pending documents) every 5 seconds
  useEffect(() => {
    const interval = setInterval(async () => {
      if (isApiOnline && isAuthenticated && !state.processingBatch) {
        try {
          const queue = await getUnUploadedDocumentQueue();
          if (queue.length === 0) {
            console.log("No documents to synchronize.");
            return;
          }
          await syncDocuments();
        } catch (error) {
          console.error("Error synchronizing documents:", error);
        }
      } else {
        console.log("Offline or processing batch in InfoCaptureProvider");
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [isApiOnline, state.processingBatch, isAuthenticated]);

  // Prevent refreshing the page when there are documents in the queue
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (state.queueLength > 0 && isApiOnline && isAuthenticated) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [isAuthenticated, isApiOnline, state.processingBatch, state.queueLength]);

  // Listen for changes in processingBatch and reset it after 5 seconds
  useEffect(() => {
    if (state.processingBatch) {
      console.log("processingBatch is true");
      const timeout = setTimeout(() => {
        dispatch({ type: "SET_PROCESSING_BATCH", payload: false });
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [state.processingBatch]);

  const clearStale = async () => {
    console.log("Cleared stale queue");
    await clearStaleQueue();
    dispatch({ type: "SET_STALE", payload: false });
  };

  return (
    <InfoCaptureContext.Provider value={{ state, dispatch, clearStale }}>
      {children}
    </InfoCaptureContext.Provider>
  );
};

export { InfoCaptureContext };
export default InfoCaptureProvider;
