import { createContext, Dispatch } from "react";
import { InfoCaptureAction, InfoCaptureState } from "../types";

const initialState: InfoCaptureState = {
  loading: false,
  processingBatch: false,
  error: null,
  groupID: "",
  stale: true,
  queueLength: 0,
  submitting: false,
};

const InfoCaptureContext = createContext<{
  state: InfoCaptureState;
  dispatch: Dispatch<InfoCaptureAction>;
  clearStale: () => Promise<void>;
}>({
  state: initialState,
  dispatch: () => null,
  clearStale: async () => {},
});

const infoCaptureReducer = (
  state: InfoCaptureState,
  action: InfoCaptureAction
): InfoCaptureState => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_ERROR":
      return { ...state, error: action.payload };
    case "SET_GROUPID":
      console.log("Group ID:", action.payload);
      return { ...state, groupID: action.payload };
    case "SET_STALE":
      return { ...state, stale: action.payload };
    case "SET_QUEUE_LENGTH":
      return { ...state, queueLength: action.payload };
    case "SET_PROCESSING_BATCH":
      return { ...state, processingBatch: action.payload };
    case "SET_SUBMITTING":
      return { ...state, submitting: action.payload };
    default:
      return state;
  }
};
export { InfoCaptureContext, infoCaptureReducer, initialState };
