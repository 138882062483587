import { Button } from "@/shadcn/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/shadcn/components/ui/dialog";
import { useToast } from "@/shadcn/hooks/use-toast";
import { StoredDocument } from "@/types";
import {
  getInvalidQueueByGroupID,
  removeDocFromInvalidQueue,
  updateTrackerInvalidNumber,
} from "@/utils/documentLoader.util";
import { X } from "lucide-react";
import React, { useEffect, useState } from "react";

type InvalidDialogProps = {
  invalid: number;
  groupID: string;
  fetchQueueCallBack: () => Promise<void>;
};

const InvalidDialog: React.FC<InvalidDialogProps> = ({
  invalid,
  groupID,
  fetchQueueCallBack,
}) => {
  const [invalidQueue, setInvalidQueue] = React.useState<
    StoredDocument[] | "loading"
  >("loading");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    if (!isDialogOpen || !groupID) {
      setInvalidQueue("loading");
      return;
    }
    const run = async () => {
      const LSQueue = await getInvalidQueueByGroupID(groupID);
      if (LSQueue) {
        setInvalidQueue(LSQueue);
      } else {
        setInvalidQueue([]);
      }
    };

    run();

    // Get the invalid document queue by group ID
  }, [groupID, isDialogOpen]);

  const handleRemoveFromInvalidQueue = async (docID: string) => {
    const removed = await removeDocFromInvalidQueue(docID);

    if (removed) {
      // Update the tracker.
      const update = await updateTrackerInvalidNumber(groupID, "decrement");

      if (update) {
        await fetchQueueCallBack();
        const newQueue = await getInvalidQueueByGroupID(groupID);
        setInvalidQueue(newQueue);
        toast({
          title: "Document removed from invalid queue",
          description: "The document has been removed from the invalid queue",
        });
      }
    }
  };

  function openChangeHandler(open: boolean) {
    if (open && invalid === 0) {
      return;
    }
    setIsDialogOpen(open);
  }

  return (
    <Dialog open={isDialogOpen} onOpenChange={openChangeHandler}>
      <DialogTrigger asChild>
        <div
          className={
            invalid > 0 ? "text-blue-600 underline cursor-pointer" : ""
          }
        >
          {invalid}
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>Invalid Documents: {groupID.split("-")[0]}</DialogTitle>
        <div>
          The following documents could not be processed. Please review and
          upload different documents.
        </div>
        <div className="grid grid-cols-1 gap-3">
          {invalidQueue === "loading" ? null : invalidQueue.length === 0 ? (
            <div className="text-center text-gray-500">
              No invalid documents found.
            </div>
          ) : (
            invalidQueue.map((doc) => (
              <div key={doc.id} className="flex flex-col justify-between">
                <div className="flex flex-row justify-between items-center">
                  <div
                    className="text-sm text-blue-600 underline cursor-pointer"
                    onClick={() => {
                      const newTab = window.open();
                      if (newTab) {
                        newTab.document.body.innerHTML = `<img src="${doc.documentB64}" alt="${doc.documentName}" />`;
                      }
                    }}
                  >
                    {doc.documentName}
                  </div>
                  {/* Remove from Queue */}
                  <Button
                    onClick={(e) => {
                      handleRemoveFromInvalidQueue(doc.id);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    className="max-w-[10%]"
                    variant={"ghost"}
                    size={"sm"}
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            ))
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InvalidDialog;
