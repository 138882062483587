import { Button } from "@/shadcn/components/ui/button";
import { useToast } from "@/shadcn/hooks/use-toast";
import { DocumentQueueTracker, StoredDocument } from "@/types";
import {
  addDocumentToQueue,
  addGroupIDToTracker,
  getTrackerByGroupID,
  isDocumentInQueue,
} from "@/utils/documentLoader.util";
import { compressAndConvert } from "@/utils/fileProcessing.util";
import { fileTypeFromBlob } from "file-type";
import React, { useCallback, useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid"; // Import uuid
import { InfoCaptureContext } from "../../contexts/InfoCaptureContext";

type FileInputProps = {};

const FileInput: React.FC<FileInputProps> = () => {
  const [selectedFiles, setSelectedFiles] = useState<{
    [key: string]: { file: File; groupID: string };
  }>({});
  const { dispatch, state } = useContext(InfoCaptureContext);
  const { toast } = useToast();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const newFiles = { ...selectedFiles };
      const rejectedFiles: string[] = [];

      for (const file of acceptedFiles) {
        const fileType = await fileTypeFromBlob(file);

        if (!fileType) {
          console.error(`Unsupported file type for file: ${file.name}`);
          rejectedFiles.push(file.name);
          continue;
        }

        console.log(
          `Detected file type: ${fileType.ext}, MIME: ${fileType.mime}`
        );

        if (["png", "jpeg", "jpg", "webp"].includes(fileType.ext)) {
          console.log("File type:", fileType.ext);
          const id = uuidv4();
          newFiles[id] = { file, groupID: state.groupID };
        } else {
          console.error(`File type not supported: ${fileType.ext}`);
          rejectedFiles.push(file.name);
        }
      }

      if (rejectedFiles.length > 0) {
        Swal.fire({
          icon: "error",
          title: "Unsupported file types",
          html: `The following files are not supported: ${rejectedFiles.join(
            ", "
          )}`,
        });
      }

      setSelectedFiles(newFiles);
    },
    [selectedFiles, state.groupID]
  );

  const removeFile = (id: string) => {
    const newFiles = { ...selectedFiles };
    delete newFiles[id];
    setSelectedFiles(newFiles);
  };

  const processFile = async (
    id: string,
    fileType: string,
    file: File,
    groupID: string
  ) => {
    switch (fileType) {
      case "image/jpeg":
        console.log("File type: jpeg");

        await handleImageProcessing(id, file, groupID);
        break;
      case "image/jpg":
        await handleImageProcessing(id, file, groupID);
        break;
      case "image/webp":
        await handleImageProcessing(id, file, groupID);
        break;
      case "image/png":
        await handleImageProcessing(id, file, groupID);
        break;
      case "application/pdf":
        console.log("File type: pdf");
        break;
      default:
        console.log("File type not supported");
        toast({
          title: "Error",
          description: "File type not supported: " + fileType,
          duration: 3000,
        });
        break;
    }
  };

  const handleImageProcessing = async (
    id: string,
    file: File,
    groupID: string
  ) => {
    try {
      const result = await compressAndConvert(file);
      if (!result) {
        toast({
          title: "Error",
          description: "Failed to compress and convert image for: " + file.name,
          duration: 10000,
        });
        return;
      }

      const document: StoredDocument = {
        id: id,
        uuidGroup: groupID,
        status: "pending",
        documentB64: result,
        documentName: file.name,
        functionName: "uploadDocument",
      };
      // See InfoCaptureProvider.tsx for processing the queue
      const addDoc = await addDocumentToQueue(document);
      if (!addDoc) {
        console.error("Failed to add document to queue");
      } else {
        console.log("Document added to queue successfully!!!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Process images to be added to the queue.
  const handleSubmit = async () => {
    console.log("selectedFiles", selectedFiles);
    if (state.submitting) return;

    // Confirmation alert
    Swal.fire({
      title: `Submit ${Object.keys(selectedFiles).length} file${
        Object.keys(selectedFiles).length > 1 ? "s" : ""
      }?`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      // Confirmed
      // TODO Handling failed process files
      if (result.isConfirmed) {
        dispatch({ type: "SET_SUBMITTING", payload: true });
        dispatch({ type: "SET_PROCESSING_BATCH", payload: true });

        // TODO revisit handling failed process files
        let processedFilesCount = 0;
        // const totalFiles = Object.keys(selectedFiles).length;

        for (const key in selectedFiles) {
          // Check if document is already in the queue
          const docInQueue = await isDocumentInQueue(key);
          if (docInQueue) {
            console.log("Document already in queue");
          } else {
            try {
              await processFile(
                key,
                selectedFiles[key].file.type,
                selectedFiles[key].file,
                selectedFiles[key].groupID
              );
              processedFilesCount++;
            } catch (error) {
              console.log("Failed to process: ", error);
            }
          }
        }

        const existingTracker = await getTrackerByGroupID(state.groupID);
        console.log("Existing tracker: ", existingTracker);

        const queueTracker: DocumentQueueTracker = {
          total: existingTracker
            ? processedFilesCount + existingTracker.total
            : processedFilesCount,
          stale: existingTracker ? existingTracker.stale : 0,
          uploaded: existingTracker ? existingTracker.uploaded : 0,
          invalid: existingTracker ? existingTracker.invalid : 0,
          completed: false,
          groupID: state.groupID,
        };

        await addGroupIDToTracker(state.groupID, queueTracker);

        setSelectedFiles({});
        const groupID = uuidv4();
        dispatch({ type: "SET_GROUPID", payload: groupID });
        dispatch({ type: "SET_SUBMITTING", payload: false });
      }
    });
  };

  // Dropzone config
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/*": [".png", ".jpeg", ".jpg"] },
    noClick: false,
    multiple: true,
    maxFiles: 20,
    onDropRejected(fileRejections) {
      // Too many files
      if (
        fileRejections.some((rejection) =>
          rejection.errors.some((error) => error.code === "too-many-files")
        )
      ) {
        Swal.fire({
          icon: "error",
          title: "Too many files",
          text: "Please select a maximum of 20 files",
        });
        return;
      }

      const allFiles = fileRejections.map((file) => file.file.name);

      // Unsupported file types
      Swal.fire({
        icon: "error",
        title: "Unsupported file types",
        html: `The following files are not supported: ${allFiles.join(", ")}`,
      });
    },
  });

  return (
    <div className="w-full flex flex-col items-center justify-center p-5 ">
      <div
        {...getRootProps()}
        style={{
          border: "2px dashed #ccc",
          borderRadius: "8px",
          padding: "20px",
          textAlign: "center",
        }}
        className="cursor-pointer max-w-[500px] w-[90%]"
      >
        <input {...getInputProps()} className="max-w-[10px]" />
        {Object.keys(selectedFiles).length > 0 ? (
          <div className="p-1 flex flex-col gap-1 items-center w-full ">
            {Object.entries(selectedFiles).map(([id, file]) => (
              <div
                key={id}
                className="flex items-center gap-2 w-full justify-between"
              >
                <p className="truncate max-w-[90%]">{file.file.name}</p>
                <Button
                  onClick={(e) => {
                    removeFile(id);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  className="max-w-[10%]"
                  variant={"ghost"}
                  size={"sm"}
                >
                  ✕
                </Button>
              </div>
            ))}
          </div>
        ) : (
          <p>Drag & drop files here, or click to select files</p>
        )}
        <div className="flex flex-col items-center">
          <small>(Supported types: PNG, JPEG, JPG)</small>
          <small>(Max files: 20)</small>
          {/* Clear all button */}
          {Object.keys(selectedFiles).length > 0 && (
            <div className="flex flex-col gap-1 items-center mt-2 w-full">
              <Button
                onClick={(e) => {
                  setSelectedFiles({});
                  e.preventDefault();
                  e.stopPropagation();
                }}
                variant={"ghost"}
                size={"sm"}
                className="max-w-fit"
              >
                Clear all
              </Button>
              <Button
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  await handleSubmit();
                }}
                variant={"default"}
                // size={"sm"}
                className="w-full"
                disabled={state.submitting}
              >
                Submit
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileInput;
