import { Button } from "@/shadcn/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/shadcn/components/ui/dialog";
import { useToast } from "@/shadcn/hooks/use-toast";
import { StoredDocument } from "@/types";
import {
  getStaleQueueByGroupID,
  removeDocFromStaleQueue,
  updateTrackerStaleNumber,
} from "@/utils/documentLoader.util";
import { X } from "lucide-react";
import React, { useEffect, useState } from "react";

type StaleDialogProps = {
  stale: number;
  groupID: string;
  fetchQueueCallBack: () => Promise<void>;
};

const StaleDialog: React.FC<StaleDialogProps> = ({
  stale,
  groupID,
  fetchQueueCallBack,
}) => {
  const [staleQueue, setStaleQueue] = React.useState<
    StoredDocument[] | "loading"
  >("loading");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    if (!isDialogOpen || !groupID) {
      setStaleQueue("loading");
      return;
    }
    const run = async () => {
      const LSQueue = await getStaleQueueByGroupID(groupID);
      if (LSQueue) {
        setStaleQueue(LSQueue);
      } else {
        setStaleQueue([]);
      }
    };

    run();

    // Get the stale document queue by group ID
  }, [groupID, isDialogOpen]);

  const handleRemoveFromStaleQueue = async (docID: string) => {
    const removed = await removeDocFromStaleQueue(docID);

    if (removed) {
      // Update the tracker.
      const update = await updateTrackerStaleNumber(groupID, "decrement");

      if (update) {
        await fetchQueueCallBack();
        const newQueue = await getStaleQueueByGroupID(groupID);
        setStaleQueue(newQueue);
        toast({
          title: "Document removed from stale queue",
          description: "The document has been removed from the stale queue",
        });
      }
    }
  };

  function openChangeHandler(open: boolean) {
    if (open && stale === 0) {
      return;
    }
    setIsDialogOpen(open);
  }

  return (
    <Dialog open={isDialogOpen} onOpenChange={openChangeHandler}>
      <DialogTrigger asChild>
        <div
          className={stale > 0 ? "text-blue-600 underline cursor-pointer" : ""}
        >
          {stale}
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>Stale Documents: {groupID.split("-")[0]}</DialogTitle>
        <div className="grid grid-cols-1 gap-3">
          {staleQueue === "loading" ? null : staleQueue.length === 0 ? (
            <div className="text-center text-gray-500">
              No stale documents found.
            </div>
          ) : (
            staleQueue.map((doc) => (
              <div key={doc.id} className="flex flex-col justify-between">
                <div className="flex flex-row justify-between items-center">
                  <div
                    className="text-sm text-blue-600 underline cursor-pointer"
                    onClick={() => {
                      const newTab = window.open();
                      if (newTab) {
                        newTab.document.body.innerHTML = `<img src="${doc.documentB64}" alt="${doc.documentName}" />`;
                      }
                    }}
                  >
                    {doc.documentName}
                  </div>
                  {/* Remove from Queue */}
                  <Button
                    onClick={(e) => {
                      handleRemoveFromStaleQueue(doc.id);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    className="max-w-[10%]"
                    variant={"ghost"}
                    size={"sm"}
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            ))
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default StaleDialog;
