import { createContext } from "react";

export interface NetworkStatusContextType {
  isOnline: boolean;
  isApiOnline: boolean;
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
}

// Create a context with a default value
export const NetworkStatusContext = createContext<NetworkStatusContextType>({
  isOnline: false, // Default value
  isApiOnline: false, // Default value
  isAuthenticated: false,
  setIsAuthenticated: () => {},
});
