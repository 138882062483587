import { InfoCaptureContext } from "@/contexts/InfoCaptureContext";
import { Button } from "@/shadcn/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/shadcn/components/ui/card";
import { ScrollArea } from "@/shadcn/components/ui/scroll-area";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/shadcn/components/ui/sheet";
import { useToast } from "@/shadcn/hooks/use-toast";
import { DocumentQueueTracker } from "@/types";
import {
  addStaleToDocumentQueue,
  clearCompletedTrackers,
  getAllTrackers,
  markGroupIDComplete,
  removeDocumentsByGroupIDInStaleQueue,
  removeTrackerByGroupID,
  syncDocs,
} from "@/utils/documentLoader.util";
import _ from "lodash";
import { RefreshCcw, WalletCards } from "lucide-react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import InvalidDialog from "../InvalidDialog/invalidDialog.component";
import StaleDialog from "../StaleDialog/staleDialog.component";

type DocumentTrackerProps = {};

const DocumentTracker: React.FC<DocumentTrackerProps> = () => {
  const { dispatch, state } = useContext(InfoCaptureContext);
  const [sheetOpen, setSheetOpen] = useState(false);
  const [trackerQueue, setTrackerQueue] = useState<DocumentQueueTracker[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [processedTrackers, setProcessedTrackers] = useState<
    DocumentQueueTracker[]
  >([]);
  const { toast } = useToast();
  const nav = useNavigate();

  const checkTrackerQueue = useCallback(async () => {
    const trackers = await getAllTrackers();
    if (!_.isEqual(trackers, trackerQueue)) {
      setTrackerQueue(trackers);
    }
  }, [trackerQueue]);

  useEffect(() => {
    checkTrackerQueue();
    // Set up interval to run every 10 seconds
    const interval = setInterval(checkTrackerQueue, 10 * 1000);
    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(interval);
  }, [checkTrackerQueue]);

  useEffect(() => {
    const processTrackers = async () => {
      const results = await Promise.all(
        trackerQueue.map(async (tracker) => {
          return { ...tracker };
        })
      );
      setProcessedTrackers(results);
    };

    processTrackers();
  }, [trackerQueue]);

  const handleConfirmation = async (groupID: string) => {
    setSubmitting(true);
    const markComplete = await markGroupIDComplete(groupID);
    if (markComplete) {
      await checkTrackerQueue();
      await removeDocumentsByGroupIDInStaleQueue(groupID);
    } else {
      toast({
        title: "Error marking group as complete",
        description: "Please try again later",
      });
    }
    setSubmitting(false);
  };

  const handleClearCompletes = async () => {
    setSubmitting(true);
    await clearCompletedTrackers();
    await checkTrackerQueue();
    setSubmitting(false);
  };

  const handleRemoveFromTracker = async (groupID: string) => {
    setSubmitting(true);
    await removeTrackerByGroupID(groupID);
    await checkTrackerQueue();
    setSubmitting(false);
  };

  function continueWithGroup(groupID: string) {
    console.log("Continue with group: ", groupID);
    if (state.processingBatch) {
      return;
    }
    nav("/home");
    dispatch({ type: "SET_GROUPID", payload: groupID });
    setSheetOpen(false);
  }

  return (
    <Sheet open={sheetOpen} onOpenChange={(open) => setSheetOpen(open)}>
      <SheetTrigger className="text-sm">
        <WalletCards className="w-4 h-4" />
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle className="flex justify-between items-center">
            <div className="flex items-center">
              Tracker Queue
              <Button
                className=""
                variant={"ghost"}
                size={"sm"}
                onClick={async () => {
                  await syncDocs();
                }}
              >
                <RefreshCcw className="w-10 h-10" />
              </Button>
            </div>
            {
              // if there are completed trackers, show the clear completed button
              trackerQueue.some((tracker) => tracker.completed) && (
                <Button
                  className="me-3"
                  variant={"default"}
                  size={"sm"}
                  onClick={async () => {
                    await handleClearCompletes();
                  }}
                >
                  Clear Completed
                </Button>
              )
            }
          </SheetTitle>
          <SheetDescription></SheetDescription>
          <ScrollArea className="h-[75vh] md:h-[90vh] lg:h-[90vh] pe-3">
            {processedTrackers.length > 0 ? (
              processedTrackers.map((tracker, index) => (
                <Card key={index} className="mb-5">
                  <CardHeader>
                    <CardTitle>
                      <div className="justify-between items-center md:flex">
                        <div className="mb-2 md:mb-0">
                          Group ID: {tracker.groupID.split("-")[0]}
                        </div>
                        {!tracker.completed && (
                          <Button
                            variant={"default"}
                            size={"sm"}
                            onClick={() => continueWithGroup(tracker.groupID)}
                          >
                            Continue
                          </Button>
                        )}
                      </div>
                    </CardTitle>
                    <CardDescription>
                      Group Status:{" "}
                      <span
                        className={`${
                          tracker.uploaded === tracker.total &&
                          tracker.completed
                            ? "text-green-600"
                            : tracker.invalid > 0
                            ? "text-red-600"
                            : "text-yellow-600"
                        }`}
                      >
                        {tracker.uploaded === tracker.total &&
                        !tracker.completed
                          ? "All Submitted"
                          : tracker.uploaded === tracker.total &&
                            tracker.completed
                          ? "Completed"
                          : tracker.invalid > 0
                          ? "Invalid"
                          : "Pending"}
                      </span>
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <div>Submitted: {tracker.total}</div>
                    <div>Processed: {tracker.uploaded}</div>
                    <div className={`m-auto w-fit md:w-full`}>
                      <span className="flex flex-row items-center gap-1">
                        Stale:{" "}
                        <StaleDialog
                          stale={tracker.stale}
                          groupID={tracker.groupID}
                          fetchQueueCallBack={checkTrackerQueue}
                        />
                      </span>
                    </div>
                    <div className={`m-auto w-fit md:w-full`}>
                      <span className="flex flex-row items-center gap-1">
                        Invalid:{" "}
                        <InvalidDialog
                          invalid={tracker.invalid}
                          groupID={tracker.groupID}
                          fetchQueueCallBack={checkTrackerQueue}
                        />
                      </span>
                    </div>
                  </CardContent>
                  <CardFooter className="flex justify-end gap-2">
                    {((tracker.uploaded >= tracker.total &&
                      !tracker.completed) ||
                      (tracker.uploaded > 0 && tracker.invalid > 0)) && (
                      <Button
                        variant={"default"}
                        size={"sm"}
                        onClick={async () =>
                          await handleConfirmation(tracker.groupID)
                        }
                        disabled={submitting}
                      >
                        Mark Complete
                      </Button>
                    )}
                    {tracker.stale > 0 && (
                      <Button
                        variant={"default"}
                        size={"sm"}
                        onClick={async () => {
                          await addStaleToDocumentQueue(tracker.groupID);
                        }}
                        disabled={submitting}
                      >
                        Retry
                      </Button>
                    )}
                    {tracker.invalid > 0 && (
                      <Button
                        variant={"default"}
                        size={"sm"}
                        onClick={async () => {
                          await handleRemoveFromTracker(tracker.groupID);
                        }}
                        disabled={submitting}
                      >
                        Remove
                      </Button>
                    )}
                  </CardFooter>
                </Card>
              ))
            ) : (
              <div>No documents in Queue</div>
            )}
          </ScrollArea>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};

export default DocumentTracker;
